'use client';

import { Popover, PopoverTrigger, PopoverContent, Image } from '@nextui-org/react';
import { useClientTranslation } from '@/hook/translate';
import { useParams, usePathname, useRouter } from 'next/navigation';
import clsx from 'clsx';
import { useCallback, useState } from 'react';
import { ListenBriefImg } from '@/config/img/listenbiref';
import { dealLng, dealPath } from '@/utils';
import { getBookCategory } from '@/request/AudioStationFetch';

export default function LanguageChange() {
  const { t } = useClientTranslation();
  const params = useParams();
  const path = usePathname();
  const router = useRouter();
  const [isOpen, setIsOpen] = useState(false);
  const languageList = [
    {
      text: 'English',
      value: 'en',
    },
    {
      text: 'Español',
      value: 'es',
    }, {
      text: 'Français',
      value: 'fr',
    }, {
      text: 'Português',
      value: 'pt',
    }, {
      text: 'Deutsch',
      value: 'de',
    },
  ];
  const lng = (params.lng as string) || 'en';
  async function getHomePath(langCode: string) {
    try {
      const res = await getBookCategory(langCode);
      let categoryList = res?.data.categoryList || [];
      categoryList = categoryList.map(item => {
        item._name = dealPath(item.name);
        return item;
      });
      let cat1 = categoryList[0]._name || 'all';
      return cat1;
    } catch (error) {
      return '';
    }
  }



  // FIXME: 需要数据判断跳转后的地址是否正确
  const getCurrentLint = useCallback(async (value: string) => {
    let newPath = path.replace(lng, value);
    if (params?.pdfid) {
      newPath = `${dealLng(value)}/pdf/1`;
    }
    if (params?.category) {
      const cat = await getHomePath(value);
      newPath = `${dealLng(value)}/home/${cat}/all/1`;
    }
    return newPath;
  }, [lng, path, params]);

  const handleClick = async (language: string) => {
    const pathName = await getCurrentLint(language);
    router.push(pathName);
  };

  return (
    <div className={clsx('font-semibold text-base cursor-pointer text-white', params?.bookid ? 'hidden' : '')}>
      <Popover
        classNames={{
          content: 'bg-[#262626] py-5',
        }}
        onOpenChange={(openStatus) => setIsOpen(openStatus)}
      >
        <PopoverTrigger>
          <div className='flex items-center'>
            <span className='pr-1'>{t('language', 'common')}</span>
            <Image src={ListenBriefImg.ArrowUp} className={clsx(isOpen ? 'block' : 'hidden')} alt='arrow' width={16} height={16} />
            <Image src={ListenBriefImg.arrowDown} className={clsx(!isOpen ? 'block' : 'hidden')} alt='arrow' width={16} height={16} />
          </div>
        </PopoverTrigger>
        <PopoverContent >
          <div className="lang-list w-[140px] text-center bg-[#262626] text-16-22">
            {
              languageList.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={
                      clsx('lang-item mt-[15px] first:mt-0 cursor-pointer', lng === item.value ? 'text-[#3AD1FF]' : 'text-white')
                    }
                  onClick={() => {
                    if (item.value === lng) return;
                    handleClick(item.value);
                  }}
                  >
                    {item.text}
                    {/* <Link href={getCurrentLint(item.value)}>{item.text}</Link> */}
                  </div>
                );
              })
            }
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
}
