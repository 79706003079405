import { listenBrief } from '@/types/audioResData';
import { dealPath } from '@/utils';
import dayjs from 'dayjs';
import { Buffer } from 'buffer';
// import { getCategoryId } from '@/utils';

/**
 * fetch
 */

const lang: any = {
  es: {
    en: 'Inglés',
    fr: 'Francés',
    es: 'Español',
    de: 'Alemán',
    pt: 'Portugués',
  },
  de: {
    en: 'Englisch',
    fr: 'Französisch',
    es: 'Spanisch',
    de: 'Deutsch',
    pt: 'Portugiesisch',
  },
  fr: {
    en: 'Anglais',
    fr: 'Français',
    es: 'Espagnol',
    de: 'Allemand',
    pt: 'Portugais',
  },
  pt: {
    en: 'Inglês',
    fr: 'Francês',
    es: 'Espanhol',
    de: 'Alemão',
    pt: 'Português',
  },
  en: {
    en: 'English',
    fr: 'French',
    es: 'Spanish',
    de: 'German',
    pt: 'Portuguese',
  },
};


async function getBookCategory(langCode: string) {
  try {
    const res: listenBrief.IListenBriefCategoryResponse = await fetch(`${process.env.NEXT_PUBLIC_REQUEST_URL}/audio/listen_brief/home?langCode=${langCode}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      next: { revalidate: 2592000, tags: [`listenbrief.com/${langCode}/home/`] },
    }).then(data => data.json());
    return res;
  } catch (error) {

  }
  // return [{ bookid: '1' }];
}

async function getBookList(lng: string, cat1: string, cat2: string, page: number) {
  const res = await getBookCategory(lng as string);
  let categoryList = res?.data.categoryList || [];
  categoryList = categoryList.map(item => {
    item._name = dealPath(item.name);
    return item;
  });
  let cat1Id = '';
  let cat2Id = '';
  const data = categoryList.find(item => item._name === cat1);
  if (data) {
    cat1Id = data!._id;
    if (cat2 === 'all') {
      cat2Id = 'all';
    } else if (data!.subjectList!.find(item => dealPath(item.name) === cat2)) {
      cat2Id = data!.subjectList!.find(item => dealPath(item.name) === cat2)?._id || '';
    }
  }

  if (cat1Id === '' || cat2Id === '') {
    return {
      list: [],
      totalPage: 1,
    };
  }
  const bookListRes: listenBrief.IBookList = await fetch(`${process.env.NEXT_PUBLIC_REQUEST_URL}/audio/listen_brief/category?langCode=${lng}&page=${page - 1}&size=40&categoryId=${cat1Id}&subCategoryId=${cat2Id}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
      next: { revalidate: 2592000, tags: [`listenbrief.com/${lng}/home/`] },
    },
  )
    .then(jsonData => jsonData.json());
  return {
    list: bookListRes.data.list,
    totalPage: bookListRes.data.totalPage,
  };
}


async function getBookDetail(urlId: string, lng: string) {

  try {

    const categoryList = await getBookCategory(lng).then(data => data?.data?.categoryList) || [];

    const res: listenBrief.IBookDetailResponse = await fetch(`${process.env.NEXT_PUBLIC_REQUEST_URL}/audio/listen_brief/book-detail?langCode=${lng}&urlId=${Buffer.from(urlId).toString('base64')}`, {
      headers: {
        'Content-Type': 'application/json',
      },
      next: { revalidate: 2592000, tags: [`listenbrief.com/${lng}/home/`] },
    }).then(jsonData => jsonData.json());
    const bookDetail = res.data;
    // TODO category language
    let category: string[] = [];
    categoryList.forEach(item => {
      if (bookDetail?.categoryId?.includes(item._id)) {
        category.unshift(item.name);
      }
      if (item.subjectList && item.subjectList?.length > 0) {
        item.subjectList.forEach(subItem => {
          if (bookDetail.categoryId.includes(subItem._id)) {
            category.push(subItem.name);
          }
        });
      }
    });
    let youtubeURL = '';
    if (bookDetail?.audioBook?.ytbAudio && bookDetail?.audioBook?.ytbAudio.startsWith('https://')) {
      youtubeURL =  new URL(bookDetail?.audioBook?.ytbAudio || '').searchParams?.get('v') || '';
    }
    return {
      id: bookDetail._id,
      _urlId: bookDetail._urlId,
      desc: bookDetail.desc,
      langCode: lng,
      category: category,
      title: bookDetail.title,
      cover: bookDetail.coverPath,
      about: {
        author: bookDetail.author,
        narrator: 'Ryan',
        format: 'MP3',
        ISBN: bookDetail.isbn,
        language: lang[lng][bookDetail.langCode],
        publishDate: bookDetail.audioStatusUpdateMs ? dayjs(bookDetail.audioStatusUpdateMs).format('DD/MM/YYYY') : '',
        times: 31,
        url: bookDetail.coverPath,
      },
      subtitle: bookDetail.subTitle,
      author: bookDetail.author,
      authorInfo: bookDetail.authorInfo,
      outline: bookDetail.desc,
      chapters: Object.values(bookDetail.content || {}).map(item => {
        item.id = item._id;
        return item;
      }),
      goodReads: {
        score: bookDetail.goodReadsScore,
        numberOfRaters: bookDetail.goodReadsNumberOfRaters,
      },
      score: bookDetail.goodReadsScore,
      numberOfRaters: bookDetail.goodReadsNumberOfRaters,
      insights: bookDetail.insights,
      relatedBooks: bookDetail.recommendList,
      youtubeId: youtubeURL,
      audioPath: bookDetail.audioBook?.audioPath,
    };
  } catch (error) {
    console.error(new Date().toLocaleString(), 'request/AudioStationFetch.ts/[error]:', error);
    const data = {
      id: urlId,
      langCode: lng,
      title: 'The Third Chimpanzee',
      category: ['Psychology & Happiness', 'Positive Thinking', 'Creativity', 'Public Speaking'],
      cover: {
        id: '1',
        url: 'https://cdn.bookey.app/20230512105509994/20230512105631787.png',
        width: 0,
        height: 0,
      },
      about: {
        author: 'Albert Camus',
        narrator: 'Ryan',
        format: 'MP3',
        ISBN: '978123123123421',
        language: 'English',
        publishDate: '1721288199727',
        times: '31',
        url: 'https://cdn.bookey.app/20230512105509994/20230512105631787.png',
      },
      subTitle: 'Step-by-Step Advice for Escaping the Trap of Negative Thinking',
      author: 'Wayne Dyer',
      authorInfo: 'Wayne Dyer was an American self-help author and motivational speaker who gained international prominence with his bestselling book Your Erroneous Zones, which is one of the best-selling books of all time. Born in Detroit, Michigan, Dyer overcame a challenging and troubled childhood to become a prominent figure in the self-help and personal development industry. He earned his doctorate in educational counseling and worked as a professor, but it was his work as a writer and speaker that brought him widespread acclaim. Dyer\'s teachings focused on the power of positive thinking, self-empowerment, and the importance of taking personal responsibility for one\'s own happiness and success. Throughout his career, he wrote over 40 books and appeared on numerous television and radio shows, establishing himself as a leading authority in the field of self-help and personal growth. Dyer\'s legacy continues to inspire millions of people around the world to live their best lives and achieve their full potential.',
      outline: 'There is little doubt whether or not humans are different from animals. However, modern genetics has called into question the extent of these boundaries. After all, there is only 1.6 percent of a genetic difference between humans and chimpanzees. If alien scientists came to visit us from outer space, they would not hesitate to categorize humans as a third type of chimpanzee. As such, how did humans evolve to become the way we are today? What sets us apart from all other living things and allows us to call ourselves the most dominant species on the planet? ‘The Third Chimpanzee’ attempts to provide a systematic answer to these questions.',
      chapters: [
        {
          section: 'Overview',
          _id: '1',
        },
        {
          section: 'The Evolution of Modern Humans',
          _id: '2',
        },
        {
          section: 'A Unique Lifestyle',
          _id: '3',
        },
        {
          section: 'Humans as The Most Dominate Species on the Planet',
          _id: '4',
        },
        {
          section: 'Conclusion',
          _id: '5',
        },
      ],
      goodReads: {
        score: '3.66',
        numberOfRaters: 8500,
      },
      score: '3.66',
      numberOfRaters: 8500,
      insights: [
        'Genetic Similarity and Uniqueness: Humans are nearly identical genetically to chimpanzees, yet we have unique traits like advanced language and technology.',
        'Evolution of Behavior: Human social and reproductive behaviors have deep evolutionary roots, reflecting both continuity and change from our primate ancestors.',
        'Environmental Impact: Humans have dramatically altered the environment, raising concerns about sustainability and the future of our planet and species.',
      ],
      createdAt: '1721281434955',
      relatedBooks: [
        {
          _id: 'aweasd1',
          title: 'The Courage to be Disliked',
          coverPath: 'https://cdn.bookey.app/20221028205745442/20221028210033208.png',
        },
        {
          _id: 'aweasd2',
          title: 'You Can Heal Your Life',
          coverPath: 'https://cdn.bookey.app/20200411172657744/20201015163209217.jpg',
        },
        {
          _id: 'aweasd3',
          title: 'Stop Walking On Eggshells',
          coverPath: 'https://cdn.bookey.app/20211015165105325/20211015165335482.jpg',
        },
        {
          _id: 'aweasd4',
          title: 'Tess of the D\'Urbervilles',
          coverPath: 'https://cdn.bookey.app/20210924095117189/20210924095444458.jpg',
        },
        {
          _id: 'aweasd5',
          title: 'The Lean Startup',
          coverPath: 'https://cdn.bookey.app/20210205185133968/20210220174700002.jpg',
        },
        {
          _id: 'aweasd6',
          title: 'The Black Swan',
          coverPath: 'https://cdn.bookey.app/20200329152927567/20201017172226835.jpg',
        },
        {
          _id: 'aweasd7',
          title: 'Can\'t Hurt Me',
          coverPath: 'https://cdn.bookey.app/20211210212936143/20211210213252110.jpg',
        },
        {
          _id: 'aweasd8',
          title: 'Me Talk Pretty One Day',
          coverPath: 'https://cdn.bookey.app/files/publish-book/Me-Talk-Pretty-One-Day.jpg',
        },
        {
          _id: 'aweasd9',
          title: 'Quiverfull',
          coverPath: 'https://cdn.bookey.app/files/publish-book/quiverfull.jpg',
        },
        {
          _id: 'aweasd10',
          title: 'The Gnostic Bible',
          coverPath: 'https://cdn.bookey.app/files/publish-book/The-Gnostic-Bible.jpg',
        },
      ],
      updatedAt: '1721281434955',
      youtubeId: 'e3BIRJUWh3E',
    };

    return data;
  }





}

async function getPDFList(lng: string, page: number | string) {
  try {
    const res: listenBrief.IPDFListData = await fetch(`${process.env.NEXT_PUBLIC_REQUEST_URL}/audio/listen_brief/pdf/page?langCode=${lng}&size=500&page=${page}&sort=pdfUpdateMs,desc`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: process.env.NEXT_PUBLIC_AUTHORIZATION || '',
      },
      next: { revalidate: 2592000, tags: [`listenbrief.com/${lng}/pdf/`] },
    }).then(pdfRes => pdfRes.json());
    return res;
  } catch (error: Error | any) {
    console.log('🍿request/AudioStationFetch.ts:71/[error]:', error.message);
    return {
      code: 200,
      message: 'mock',
      data: {
        list: [],
        totalPage: 1,
      },
    } as any as listenBrief.IPDFListData;
  }
}

export {
  getBookCategory,
  getBookList,
  getBookDetail,
  getPDFList,
};


