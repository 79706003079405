'use client';
import { TbMenu2 } from 'react-icons/tb';
import { useClientTranslation } from '@/hook/translate';
import { Popover, PopoverTrigger, PopoverContent, Image } from '@nextui-org/react';
import { useActivePath } from '@/hook';
import { usePathname, useRouter, useParams } from 'next/navigation';
import { useCallback, useState } from 'react';
import clsx from 'clsx';
import { ListenBriefImg } from '@/config/img/listenbiref';
import { dealLng } from '@/utils';

export default function SmHeader() {
  const { t } = useClientTranslation();

  const activePath = useActivePath(['bookmagazine', 'collection', 'pdf']);
  const pathname = usePathname();
  const router = useRouter();
  // const goBookMagazine = useCallback(() => {
  //   const lng = pathname.split('/').filter(item => item && item !== '/')[0];
  //   router.push('/' + lng + '/bookmagazine/list/1/');
  // }, [pathname, router]);

  // const goCollection = useCallback(() => {
  //   const lng = pathname.split('/').filter(item => item && item !== '/')[0];
  //   router.push('/' + lng + '/collection/list/1/');
  // }, [pathname, router]);


  const params = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const languageList = [
    {
      text: 'English',
      value: 'en',
    },
    {
      text: 'Español',
      value: 'es',
    }, {
      text: 'Français',
      value: 'fr',
    }, {
      text: 'Português',
      value: 'pt',
    }, {
      text: 'Deutsch',
      value: 'de',
    },
  ];
  const lng = (params.lng as string) || 'en';
  // FIXME: 需要数据判断跳转后的地址是否正确
  const handleClick = (value: string) => {
    let newPath = pathname.replace(lng, value);

    if (params?.pdfid) {
      newPath = `${dealLng(value)}/pdf/1`;
    }
    if (params?.category) {
      newPath = `${dealLng(value)}/home/all/all/1`;
    }
    router.push(newPath);
  };

  const goPDF = useCallback(() => {
    let lngs = dealLng(params.lng as string || '');
    console.log(lngs);
    router.push(lngs + '/pdf/1/');
  }, [params, router]);

  return (
    <div className='h-[50px] flex items-center px-[15px] bg-black'>
      <Image src={ListenBriefImg.ListenbriefLogo} alt='logo' width={140} height={29} />
      <div className='flex-1 flex justify-end left-0'>
        {/* <Dropdown classNames={{
          content: 'min-w-[50px] dark w-full',
        }}
        placement='top-start'
        >
          <DropdownTrigger>
            <span>
              <TbMenu2 className='w-6 h-6 text-[#6bcefa]' />
            </span>
          </DropdownTrigger>
          <DropdownMenu aria-label="Static Actions">
            <DropdownItem key="new" textValue={ t('bookMagazine', 'listenBriefs') }>
              <span className={
                clsx( activePath[0] ? 'text-[#3AD1FF]' : 'text-white')
              } onClick={goBookMagazine}>{ t('bookMagazine', 'listenBriefs') }</span>
            </DropdownItem>
            <DropdownItem key="copy" textValue={ t('collection', 'listenBriefs') }>
            <span className={
                clsx(activePath[1] ? 'text-[#3AD1FF]' : 'text-white')
              } onClick={goCollection}>{ t('collection', 'listenBriefs') }</span>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown> */}
        <Popover
          classNames={{
            content: 'bg-transparent relative w-screen rounded-none',
          }}
          placement='bottom-start'
        >
          <PopoverTrigger>
            <div className='flex items-center'>
              <TbMenu2 className='w-6 h-6 text-[#6bcefa]' />
            </div>
          </PopoverTrigger>
          <PopoverContent >
            <div className="lang-list w-screen py-5 absolute top-0 -left-3 text-center bg-[#262626] text-18-27">
              {/* <div
                className={
                  clsx(activePath[0] ? 'text-[#3AD1FF]' : 'text-white', 'cursor-pointer')
                }
                onClick={goBookMagazine}
              >
                {t('bookMagazine', 'listenBriefs')}
              </div> */}
              {/* <div
                className={
                  clsx(activePath[1] ? 'text-[#3AD1FF]' : 'text-white', 'mt-5 cursor-pointer')
                }
                onClick={goCollection}
              >
                {t('collection', 'listenBriefs')}
              </div> */}
              <div
                className={
                  clsx(activePath[2] ? 'text-[#3AD1FF]' : 'text-white', ' cursor-pointer')
                }
                onClick={goPDF}
              >
                PDF
              </div>
              <div className='text-white mt-5 flex justify-center items-center cursor-pointer' onClick={() => setIsOpen(!isOpen)}>
                <span className='pr-1'>{t('language')}</span>
                <Image src={ListenBriefImg.ArrowUp} className={clsx(isOpen ? 'block' : 'hidden')} alt='arrow' width={16} height={16} />
                <Image src={ListenBriefImg.arrowDown} className={clsx(!isOpen ? 'block' : 'hidden')} alt='arrow' width={16} height={16} />
              </div>
              <div className={clsx(isOpen ? 'block' : 'hidden')}>
                {
                  languageList.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className={
                          clsx('lang-item mt-[15px] first:mt-[10px]  cursor-pointer', lng === item.value ? 'text-[#3AD1FF]' : 'text-white')
                        }
                        onClick={() => {
                          if (item.value === lng) return;
                          handleClick(item.value);
                        }}
                      >
                        {item.text}
                      </div>
                    );
                  })
                }
              </div>
            </div>
          </PopoverContent>
        </Popover>
      </div>
    </div>
  );
}
