'use client';
import clsx from 'clsx';
import { useClientTranslation } from '@/hook/translate';
import { useActivePath } from '@/hook';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'next/navigation';
import { useRouter } from 'next/navigation';
import { ListenBriefImg } from '@/config/img/listenbiref';
import LanguageChange from './components/LanguageChange';
// import { Image } from '@nextui-org/react';
import { dealLng, dealPath } from '@/utils';
import { getBookCategory } from '@/request/AudioStationFetch';
import Link from 'next/link';
import Image from 'next/image';


export default function BigHeader() {
  const { t } = useClientTranslation();
  const [homeCategory, setHomeCategory] = useState('all');
  const activePath = useActivePath(['bookmagazine', 'collection', 'pdf']);
  const params = useParams();

  const router = useRouter();
  const imageLoader = useCallback(({ src }: { src: string }) => {
    return src;
  }, []);
  const goBookMagazine = useCallback(() => {
    let lng = dealLng(params.lng as string || '');
    router.push(lng + '/bookmagazine/list/1/');
  }, [params, router]);


  const goCollection = useCallback(() => {
    let lng = dealLng(params.lng as string || '');
    console.log(lng);
    router.push(lng + '/collection/list/1/');
  }, [params, router]);

  const goPDF = useCallback(() => {
    let lng = dealLng(params.lng as string || '');
    console.log(lng);
    router.push(lng + '/pdf/1/');
  }, [params, router]);

  useEffect(() => {
    (async () => {
      const res = await getBookCategory(params.lng as string);
      setHomeCategory(res?.data.categoryList[0]?.name || 'all');
    })();
  }, [params]);
  return (
    <div className='h-20 flex items-center px-[150px] bg-black'>
      <Link href={ `${dealLng(params.lng as string)}/home/${ dealPath(homeCategory) }/all/1` }>
        <Image priority loader={ imageLoader } src={ ListenBriefImg.ListenbriefLogo } alt='logo' width={170} height={35} className='cursor-pointer' />
      </Link>
      <div className='flex-1 flex justify-end'>
        <p 
          className={
            clsx(' mr-10 font-semibold text-base cursor-pointer hidden',
              activePath[0] ? 'text-[#3AD1FF]' : 'text-white',
            )
          }
          onClick={goBookMagazine}
        >{ t('bookMagazine', 'listenBriefs') }</p>
        <p className={
          clsx('mr-10 font-semibold text-base cursor-pointer hidden',
            activePath[1] ? 'text-[#3AD1FF]' : 'text-white',
          )
        } onClick={goCollection}>{ t('collection', 'listenBriefs') }</p>
        <p 
          className={
            clsx(' mr-10 font-semibold text-base cursor-pointer',
              activePath[2] ? 'text-[#3AD1FF]' : 'text-white',
            )
          }
          onClick={goPDF}
        >PDF</p>
        <LanguageChange />
      </div>
    </div>
  );
}
